<template>
    <div class="background">
        <blackjack21-dealer-hand :dealer-hand="stateData.currentSpinResult.dealerHand"
                                           :style="getDealerHandPosition()"
                                           style="position: relative"
        />

        <!-- Игроки -->
        <template v-for="(playerHand, index) in stateData.currentSpinResult.playerHands">
            <!-- Если рука заспличена -->
            <template v-if="playerHand.isSplitted">
                <blackjack21-splitted-player-hand
                    :key="`ind-1-${index}`"
                    :denom="stateData.denom"
                    :is-spin-end="stateData.currentSpinResult.dealerHand.isEnd"
                    :player-hand="playerHand.firstSplitHand"

                    :style="getPlayerSplittedHandPosition(playerHand.place,1)"
                />

                <blackjack21-splitted-player-hand
                    :key="`ind-2-${index}`"
                    :denom="stateData.denom"
                    :is-spin-end="stateData.currentSpinResult.dealerHand.isEnd"
                    :player-hand="playerHand.secondSplitHand"

                    :style="getPlayerSplittedHandPosition(playerHand.place,2)"
                    style="position: relative"
                />
            </template>
            <template v-else>
                <blackjack21-player-hand
                    :key="`ind-${index}`"
                    :denom="stateData.denom"
                    :is-spin-end="stateData.currentSpinResult.dealerHand.isEnd"
                    :player-hand="playerHand"

                    :style="getPlayerHandPosition(playerHand.place)"
                    style="position: relative"
                />
            </template>
        </template>
    </div>
</template>

<script>
import Blackjack21PlayerHand from "./Blackjack21PlayerHand";
import Blackjack21SplittedPlayerHand from "./Blackjack21SplittedPlayerHand";
import Blackjack21DealerHand from "./Blackjack21DealerHand";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        playerHands: [{
                            isSplitted: null,
                            place: null,
                            firstSplitHand: null,
                            secondSplitHand: null,
                        }],
                        dealerHand: null,
                    },
                    denom: null
                }
            }
        }
    },
    data() {
        return {
            player: {
                hands: {
                    base: {
                        1: {
                            left: 215,
                            top: 366
                        },
                        2: {
                            left: 492,
                            top: 424
                        },
                        3: {
                            left: 771,
                            top: 366
                        }
                    },
                    splitted: {
                        1: {
                            1: {
                                left: 215,
                                top: 300
                            },
                            2: {
                                left: 215,
                                top: 432
                            }
                        },
                        2: {
                            1: {
                                left: 492,
                                top: 358
                            },
                            2: {
                                left: 492,
                                top: 490
                            }
                        },
                        3: {
                            1: {
                                left: 771,
                                top: 300
                            },
                            2: {
                                left: 771,
                                top: 432
                            }
                        }
                    }
                },
            },
            dealer: {
                left: 492,
                top: 175
            }
        }
    },
    computed: {
        backgroundImage() {
            return require('@/assets/games/blackjack/blackjack-21/bg.png');
        }
    },
    methods: {
        getPlayerHandPosition(handNumber) {
            let left = 0, top = 0;

            let handPositions = this.player.hands.base[handNumber];

            left = handPositions.left;
            top = handPositions.top;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        },
        getPlayerSplittedHandPosition(handNumber, splittedNumber) {
            let left = 0, top = 0;

            let handPositions = this.player.hands.splitted[handNumber][splittedNumber];

            left = handPositions.left;
            top = handPositions.top;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        },
        getDealerHandPosition() {
            let left = 0, top = 0;

            left = this.dealer.left;
            top += this.dealer.top;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        }
    },
    components: {
        Blackjack21PlayerHand,
        Blackjack21SplittedPlayerHand,
        Blackjack21DealerHand
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 714);
        this.$store.dispatch('workspace/setWidth', 1098);
    }
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/blackjack/blackjack/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1098px;
    height: 714px;
}
</style>
